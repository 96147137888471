var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.test)?_c('div',{staticClass:"mt-3 mb-0"},[_vm._v(" Puntaje total de "),_c('span',[_vm._v(" "+_vm._s(_vm.test.title)+": "),_c('strong',[_vm._v(" "+_vm._s(_vm.test.max_score)+" ")])])]):_vm._e(),_c('b-table-simple',{attrs:{"bordered":"","small":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"th-class",attrs:{"rowspan":2}},[_vm._v(" NIVEL COGNITIVO ")]),_c('b-th',{staticClass:"th-class",attrs:{"rowspan":2}},[_vm._v(" CANTIDAD DE "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
              `${_vm.$getVisibleNames(
                'manual.indicador_de_logro',
                true,
                'Indicadores de Logros'
              ).toUpperCase()}`
            ),expression:"\n              `${$getVisibleNames(\n                'manual.indicador_de_logro',\n                true,\n                'Indicadores de Logros'\n              ).toUpperCase()}`\n            ",modifiers:{"v-secondary":true,"noninteractive":true}}]},[_vm._v(" (IL) ")])]),_c('b-th',{staticClass:"th-class",attrs:{"colspan":10}},[_c('span',[_vm._v(" SUB-TIPO DE PREGUNTAS ")]),(
              _vm.allows_crud &&
              _vm.test &&
              !_vm.test.question_creation_is_locked &&
              (!_vm.evaluationSituation ||
                (_vm.evaluationSituation &&
                  ![
                    'duoc_ejecucion_practica_test',
                    'duoc_entrega_de_encargo_test',
                    'duoc_presentacion_test',
                  ].includes(_vm.evaluationSituation.internal_use_id) &&
                  _vm.evaluationSituation.enable_questions &&
                  (_vm.evaluationSituation.enable_redaction_questions ||
                    _vm.evaluationSituation.enable_simple_selection_questions ||
                    _vm.evaluationSituation.enable_multiple_selection_questions ||
                    _vm.evaluationSituation.enable_matching_questions)))
            )?_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(`Agregar Pregunta`),expression:"`Agregar Pregunta`",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"m-0 mb-1 p-0 text-white",attrs:{"size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`modal-created-question-nw-st-cgl-${_vm.test.id}`)}}}):_vm._e()],1)],1),_c('b-tr',[_c('b-th',{staticClass:"th-class"},[_vm._v("DESARROLLO BREVE")]),_c('b-th',{staticClass:"th-class"},[_vm._v("PUNTAJE AGREGADO")]),_c('b-th',{staticClass:"th-class"},[_vm._v("DESARROLLO EXTENSO")]),_c('b-th',{staticClass:"th-class"},[_vm._v("PUNTAJE AGREGADO")]),_c('b-th',{staticClass:"th-class"},[_vm._v("SELECCIÓN SIMPLE")]),_c('b-th',{staticClass:"th-class"},[_vm._v("PUNTAJE AGREGADO")]),_c('b-th',{staticClass:"th-class"},[_vm._v("SELECCIÓN MÚLTIPLE")]),_c('b-th',{staticClass:"th-class"},[_vm._v("PUNTAJE AGREGADO")]),_c('b-th',{staticClass:"th-class"},[_vm._v("EMPAREJAMIENTO")]),_c('b-th',{staticClass:"th-class"},[_vm._v("PUNTAJE AGREGADO")])],1)],1),_c('b-tbody',_vm._l((_vm.cognitiveLevelList),function(cognitive_level,index){return _c('b-tr',{key:'NewCognitiveLevelST:' + index + '-' + _vm.test.id},[_c('b-td',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
            `${
              cognitive_level.id != null
                ? cognitive_level.level + '. ' + cognitive_level.name
                : ''
            }`
          ),expression:"\n            `${\n              cognitive_level.id != null\n                ? cognitive_level.level + '. ' + cognitive_level.name\n                : ''\n            }`\n          ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"text-center",class:{ 'underline-cell': cognitive_level.id != null }},[_vm._v(_vm._s(cognitive_level.level))]),_c('b-td',{staticClass:"text-center"},[(
              _vm.countUniqueIL(
                cognitive_level.questions,
                false,
                cognitive_level.id
              ) > 0
            )?[_vm._v(" "+_vm._s(_vm.countUniqueIL( cognitive_level.questions, false, cognitive_level.id ))+" ")]:[_vm._v("-")]],2),_c('b-td',{staticClass:"text-center"},[(
              cognitive_level.questions.filter(
                (x) =>
                  x.question_type == 1 && x.max_answer_length_type != null
              ).length > 0
            )?_c('div',[_c('div',{staticClass:"underline-cell",attrs:{"id":`cl-questions-cell-${cognitive_level.id}-${_vm.test.id}-q_type-1-q_sub_type-1`}},[_vm._v(" "+_vm._s(cognitive_level.questions.filter( (x) => x.question_type == 1 && x.max_answer_length_type != null ).length)+" ")]),_vm._l((cognitive_level.questions.filter(
                (x) =>
                  x.question_type == 1 && x.max_answer_length_type != null
              )),function(question){return [_c('b-modal',{key:`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`,attrs:{"id":`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`,"title":`Editar ${_vm.$getVisibleNames(
                  'evaluations2.redactionquestion',
                  false,
                  'Pregunta'
                )}`,"hide-footer":"","size":"lg"}},[_c('NewQuestionForm',{attrs:{"test_id":question.test,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"Question":question,"evaluation_situations":_vm.evaluation_situations,"show_delete_button":true},on:{"closeModal":function($event){return _vm.$bvModal.hide(
                      `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                    )}}})],1)]}),_c('b-popover',{attrs:{"target":`cl-questions-cell-${cognitive_level.id}-${_vm.test.id}-q_type-1-q_sub_type-1`,"triggers":"hover"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Preguntas")]},proxy:true}],null,true)},_vm._l((cognitive_level.questions.filter(
                  (x) =>
                    x.question_type == 1 && x.max_answer_length_type != null
                )),function(question){return _c('div',{key:question.id +
                  '-' +
                  question.question_type +
                  '-' +
                  cognitive_level.id +
                  '-' +
                  _vm.test.id},[_c('b-icon',{attrs:{"icon":"circle-fill","scale":"0.35"}}),_vm._v(" Pregunta N° "),(question.segment_order != null)?_c('span',[_vm._v(" "+_vm._s(question.segment_order)+". ")]):_vm._e(),_vm._v(" "+_vm._s(question.order)+" "),(_vm.allows_crud && !question.is_locked)?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    `Editar Pregunta`
                  ),expression:"\n                    `Editar Pregunta`\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"m-0 p-0",on:{"click":function($event){return _vm.$bvModal.show(
                      `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                    )}}}):_vm._e()],1)}),0)],2):[_vm._v("-")]],2),_c('b-td',{staticClass:"text-center"},[(
              cognitive_level.questions.filter(
                (x) =>
                  x.question_type == 1 && x.max_answer_length_type != null
              ).length > 0
            )?[_vm._v(" "+_vm._s(cognitive_level.questions .filter( (x) => x.question_type == 1 && x.max_answer_length_type != null ) .reduce((acc, x) => acc + parseFloat(x.max_score), 0))+" ")]:[_vm._v("-")]],2),_c('b-td',{staticClass:"text-center"},[(
              cognitive_level.questions.filter(
                (x) =>
                  x.question_type == 1 && x.max_answer_length_type == null
              ).length > 0
            )?_c('div',[_c('div',{staticClass:"underline-cell",attrs:{"id":`cl-questions-cell-${cognitive_level.id}-${_vm.test.id}-q_type-1-q_sub_type-2`}},[_vm._v(" "+_vm._s(cognitive_level.questions.filter( (x) => x.question_type == 1 && x.max_answer_length_type == null ).length)+" ")]),_vm._l((cognitive_level.questions.filter(
                (x) =>
                  x.question_type == 1 && x.max_answer_length_type == null
              )),function(question){return [_c('b-modal',{key:`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`,attrs:{"id":`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`,"title":`Editar ${_vm.$getVisibleNames(
                  'evaluations2.redactionquestion',
                  false,
                  'Pregunta'
                )}`,"hide-footer":"","size":"lg"}},[_c('NewQuestionForm',{attrs:{"test_id":question.test,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"Question":question,"evaluation_situations":_vm.evaluation_situations,"show_delete_button":true},on:{"closeModal":function($event){return _vm.$bvModal.hide(
                      `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                    )}}})],1)]}),_c('b-popover',{attrs:{"target":`cl-questions-cell-${cognitive_level.id}-${_vm.test.id}-q_type-1-q_sub_type-2`,"triggers":"hover"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Preguntas")]},proxy:true}],null,true)},_vm._l((cognitive_level.questions.filter(
                  (x) =>
                    x.question_type == 1 && x.max_answer_length_type == null
                )),function(question){return _c('div',{key:question.id +
                  '-' +
                  question.question_type +
                  '-' +
                  cognitive_level.id +
                  '-' +
                  _vm.test.id},[_c('b-icon',{attrs:{"icon":"circle-fill","scale":"0.35"}}),_vm._v(" Pregunta N° "),(question.segment_order != null)?_c('span',[_vm._v(" "+_vm._s(question.segment_order)+". ")]):_vm._e(),_vm._v(" "+_vm._s(question.order)+" "),(_vm.allows_crud && !question.is_locked)?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    `Editar Pregunta`
                  ),expression:"\n                    `Editar Pregunta`\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"m-0 p-0",on:{"click":function($event){return _vm.$bvModal.show(
                      `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                    )}}}):_vm._e()],1)}),0)],2):[_vm._v("-")]],2),_c('b-td',{staticClass:"text-center"},[(
              cognitive_level.questions.filter(
                (x) =>
                  x.question_type == 1 && x.max_answer_length_type == null
              ).length > 0
            )?[_vm._v(" "+_vm._s(cognitive_level.questions .filter( (x) => x.question_type == 1 && x.max_answer_length_type == null ) .reduce((acc, x) => acc + parseFloat(x.max_score), 0))+" ")]:[_vm._v("-")]],2),_c('b-td',{staticClass:"text-center"},[(
              cognitive_level.questions.filter(
                (x) => x.question_type == 2 && x.subtype == 1
              ).length > 0
            )?_c('div',[_c('div',{staticClass:"underline-cell",attrs:{"id":`cl-questions-cell-${cognitive_level.id}-${_vm.test.id}-q_type-2-q_sub_type-1`}},[_vm._v(" "+_vm._s(cognitive_level.questions.filter( (x) => x.question_type == 2 && x.subtype == 1 ).length)+" ")]),_vm._l((cognitive_level.questions.filter(
                (x) => x.question_type == 2 && x.subtype == 1
              )),function(question){return [_c('b-modal',{key:`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`,attrs:{"id":`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`,"title":`Editar ${_vm.$getVisibleNames(
                  'evaluations2.redactionquestion',
                  false,
                  'Pregunta'
                )}`,"hide-footer":"","size":"lg"}},[_c('NewQuestionForm',{attrs:{"test_id":question.test,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"Question":question,"evaluation_situations":_vm.evaluation_situations,"show_delete_button":true},on:{"closeModal":function($event){return _vm.$bvModal.hide(
                      `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                    )}}})],1)]}),_c('b-popover',{attrs:{"target":`cl-questions-cell-${cognitive_level.id}-${_vm.test.id}-q_type-2-q_sub_type-1`,"triggers":"hover"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Preguntas")]},proxy:true}],null,true)},_vm._l((cognitive_level.questions.filter(
                  (x) => x.question_type == 2 && x.subtype == 1
                )),function(question){return _c('div',{key:question.id +
                  '-' +
                  question.question_type +
                  '-' +
                  cognitive_level.id +
                  '-' +
                  _vm.test.id},[_c('b-icon',{attrs:{"icon":"circle-fill","scale":"0.35"}}),_vm._v(" Pregunta N° "),(question.segment_order != null)?_c('span',[_vm._v(" "+_vm._s(question.segment_order)+". ")]):_vm._e(),_vm._v(" "+_vm._s(question.order)+" "),(_vm.allows_crud && !question.is_locked)?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    `Editar Pregunta`
                  ),expression:"\n                    `Editar Pregunta`\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"m-0 p-0",on:{"click":function($event){return _vm.$bvModal.show(
                      `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                    )}}}):_vm._e()],1)}),0)],2):[_vm._v("-")]],2),_c('b-td',{staticClass:"text-center"},[(
              cognitive_level.questions.filter(
                (x) => x.question_type == 2 && x.subtype == 1
              ).length > 0
            )?[_vm._v(" "+_vm._s(cognitive_level.questions .filter((x) => x.question_type == 2 && x.subtype == 1) .reduce((acc, x) => acc + parseFloat(x.max_score), 0))+" ")]:[_vm._v("-")]],2),_c('b-td',{staticClass:"text-center"},[(
              cognitive_level.questions.filter(
                (x) => x.question_type == 2 && x.subtype == 2
              ).length > 0
            )?_c('div',[_c('div',{staticClass:"underline-cell",attrs:{"id":`cl-questions-cell-${cognitive_level.id}-${_vm.test.id}-q_type-2-q_sub_type-2`}},[_vm._v(" "+_vm._s(cognitive_level.questions.filter( (x) => x.question_type == 2 && x.subtype == 2 ).length)+" ")]),_vm._l((cognitive_level.questions.filter(
                (x) => x.question_type == 2 && x.subtype == 2
              )),function(question){return [_c('b-modal',{key:`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`,attrs:{"id":`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`,"title":`Editar ${_vm.$getVisibleNames(
                  'evaluations2.redactionquestion',
                  false,
                  'Pregunta'
                )}`,"hide-footer":"","size":"lg"}},[_c('NewQuestionForm',{attrs:{"test_id":question.test,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"Question":question,"evaluation_situations":_vm.evaluation_situations,"show_delete_button":true},on:{"closeModal":function($event){return _vm.$bvModal.hide(
                      `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                    )}}})],1)]}),_c('b-popover',{attrs:{"target":`cl-questions-cell-${cognitive_level.id}-${_vm.test.id}-q_type-2-q_sub_type-2`,"triggers":"hover"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Preguntas")]},proxy:true}],null,true)},_vm._l((cognitive_level.questions.filter(
                  (x) => x.question_type == 2 && x.subtype == 2
                )),function(question){return _c('div',{key:question.id +
                  '-' +
                  question.question_type +
                  '-' +
                  cognitive_level.id +
                  '-' +
                  _vm.test.id},[_c('b-icon',{attrs:{"icon":"circle-fill","scale":"0.35"}}),_vm._v(" Pregunta N° "),(question.segment_order != null)?_c('span',[_vm._v(" "+_vm._s(question.segment_order)+". ")]):_vm._e(),_vm._v(" "+_vm._s(question.order)+" "),(_vm.allows_crud && !question.is_locked)?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    `Editar Pregunta`
                  ),expression:"\n                    `Editar Pregunta`\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"m-0 p-0",on:{"click":function($event){return _vm.$bvModal.show(
                      `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                    )}}}):_vm._e()],1)}),0)],2):[_vm._v("-")]],2),_c('b-td',{staticClass:"text-center"},[(
              cognitive_level.questions.filter(
                (x) => x.question_type == 2 && x.subtype == 2
              ).length > 0
            )?[_vm._v(" "+_vm._s(cognitive_level.questions .filter((x) => x.question_type == 2 && x.subtype == 2) .reduce((acc, x) => acc + parseFloat(x.max_score), 0))+" ")]:[_vm._v("-")]],2),_c('b-td',{staticClass:"text-center"},[(
              cognitive_level.questions.filter((x) => x.question_type == 4)
                .length > 0
            )?_c('div',[_c('div',{staticClass:"underline-cell",attrs:{"id":`cl-questions-cell-${cognitive_level.id}-${_vm.test.id}-q_type-4-q_sub_type-1`}},[_vm._v(" "+_vm._s(cognitive_level.questions.filter((x) => x.question_type == 4) .length)+" ")]),_vm._l((cognitive_level.questions.filter(
                (x) => x.question_type == 4
              )),function(question){return [_c('b-modal',{key:`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`,attrs:{"id":`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`,"title":`Editar ${_vm.$getVisibleNames(
                  'evaluations2.redactionquestion',
                  false,
                  'Pregunta'
                )}`,"hide-footer":"","size":"lg"}},[_c('NewQuestionForm',{attrs:{"test_id":question.test,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"Question":question,"evaluation_situations":_vm.evaluation_situations,"show_delete_button":true},on:{"closeModal":function($event){return _vm.$bvModal.hide(
                      `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                    )}}})],1)]}),_c('b-popover',{attrs:{"target":`cl-questions-cell-${cognitive_level.id}-${_vm.test.id}-q_type-4-q_sub_type-1`,"triggers":"hover"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Preguntas")]},proxy:true}],null,true)},_vm._l((cognitive_level.questions.filter(
                  (x) => x.question_type == 4
                )),function(question){return _c('div',{key:question.id +
                  '-' +
                  question.question_type +
                  '-' +
                  cognitive_level.id +
                  '-' +
                  _vm.test.id},[_c('b-icon',{attrs:{"icon":"circle-fill","scale":"0.35"}}),_vm._v(" Pregunta N° "),(question.segment_order != null)?_c('span',[_vm._v(" "+_vm._s(question.segment_order)+". ")]):_vm._e(),_vm._v(" "+_vm._s(question.order)+" "),(_vm.allows_crud && !question.is_locked)?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    `Editar Pregunta`
                  ),expression:"\n                    `Editar Pregunta`\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"m-0 p-0",on:{"click":function($event){return _vm.$bvModal.show(
                      `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                    )}}}):_vm._e()],1)}),0)],2):[_vm._v("-")]],2),_c('b-td',{staticClass:"text-center"},[(
              cognitive_level.questions.filter((x) => x.question_type == 4)
                .length > 0
            )?[_vm._v(" "+_vm._s(cognitive_level.questions .filter((x) => x.question_type == 4) .reduce((acc, x) => acc + parseFloat(x.max_score), 0))+" ")]:[_vm._v("-")]],2)],1)}),1),_c('b-tfoot',[_c('b-tr',{staticClass:"cl-tfoot"},[_c('b-td',{staticClass:"text-center"},[_vm._v("TOTAL")]),_c('b-td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.totalCountCL))]),_c('b-td',{staticClass:"text-center"}),_c('b-td',{staticClass:"text-center"}),_c('b-td',{staticClass:"text-center"}),_c('b-td',{staticClass:"text-center"}),_c('b-td',{staticClass:"text-center"}),_c('b-td',{staticClass:"text-center"}),_c('b-td',{staticClass:"text-center"}),_c('b-td',{staticClass:"text-center"}),_c('b-td',{staticClass:"text-center"}),_c('b-td',{staticClass:"text-center"})],1)],1)],1),(_vm.test)?_c('b-modal',{attrs:{"id":`modal-created-question-nw-st-cgl-${_vm.test.id}`,"title":`Crear Pregunta`,"hide-footer":"","size":"lg"}},[_c('NewQuestionForm',{attrs:{"test_id":_vm.test.id,"evaluation_criteria_micro_ids":_vm.evaluation_criteria_micro_ids,"order":_vm.filterQuestionBySegment().length > 0
          ? _vm.filterQuestionBySegment()[_vm.filterQuestionBySegment().length - 1]
              .order + 1
          : 1,"evaluation_situations":_vm.evaluation_situations},on:{"closeModal":function($event){return _vm.$bvModal.hide(`modal-created-question-nw-st-cgl-${_vm.test.id}`)}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }