
<template>
  <div>
    <div v-if="test" class="mt-3 mb-0">
      Puntaje total de
      <span>
        {{ test.title }}:
        <strong>
          {{ test.max_score }}
        </strong>
      </span>
    </div>
    <b-table-simple bordered small>
      <b-thead>
        <b-tr>
          <b-th class="th-class" :rowspan="2"> NIVEL COGNITIVO </b-th>
          <b-th class="th-class" :rowspan="2">
            CANTIDAD DE
            <span
              v-b-tooltip.v-secondary.noninteractive="
                `${$getVisibleNames(
                  'manual.indicador_de_logro',
                  true,
                  'Indicadores de Logros'
                ).toUpperCase()}`
              "
            >
              (IL)
            </span>
          </b-th>
          <b-th class="th-class" :colspan="10">
            <span> SUB-TIPO DE PREGUNTAS </span>
            <button-add
              v-if="
                allows_crud &&
                test &&
                !test.question_creation_is_locked &&
                (!evaluationSituation ||
                  (evaluationSituation &&
                    ![
                      'duoc_ejecucion_practica_test',
                      'duoc_entrega_de_encargo_test',
                      'duoc_presentacion_test',
                    ].includes(evaluationSituation.internal_use_id) &&
                    evaluationSituation.enable_questions &&
                    (evaluationSituation.enable_redaction_questions ||
                      evaluationSituation.enable_simple_selection_questions ||
                      evaluationSituation.enable_multiple_selection_questions ||
                      evaluationSituation.enable_matching_questions)))
              "
              class="m-0 mb-1 p-0 text-white"
              v-b-tooltip.v-secondary.top.noninteractive="`Agregar Pregunta`"
              @click="
                $bvModal.show(`modal-created-question-nw-st-cgl-${test.id}`)
              "
              size="sm"
            >
            </button-add>
          </b-th>
        </b-tr>
        <b-tr>
          <b-th class="th-class">DESARROLLO BREVE</b-th>
          <b-th class="th-class">PUNTAJE AGREGADO</b-th>
          <b-th class="th-class">DESARROLLO EXTENSO</b-th>
          <b-th class="th-class">PUNTAJE AGREGADO</b-th>
          <b-th class="th-class">SELECCIÓN SIMPLE</b-th>
          <b-th class="th-class">PUNTAJE AGREGADO</b-th>
          <b-th class="th-class">SELECCIÓN MÚLTIPLE</b-th>
          <b-th class="th-class">PUNTAJE AGREGADO</b-th>
          <b-th class="th-class">EMPAREJAMIENTO</b-th>
          <b-th class="th-class">PUNTAJE AGREGADO</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(cognitive_level, index) in cognitiveLevelList"
          :key="'NewCognitiveLevelST:' + index + '-' + test.id"
        >
          <!-- NIVEL COGNITIVO -->
          <b-td
            class="text-center"
            :class="{ 'underline-cell': cognitive_level.id != null }"
            v-b-tooltip.v-secondary.top.noninteractive="
              `${
                cognitive_level.id != null
                  ? cognitive_level.level + '. ' + cognitive_level.name
                  : ''
              }`
            "
            >{{ cognitive_level.level }}</b-td
          >
          <!-- CANTIDAD IL -->
          <b-td class="text-center">
            <template
              v-if="
                countUniqueIL(
                  cognitive_level.questions,
                  false,
                  cognitive_level.id
                ) > 0
              "
            >
              {{
                countUniqueIL(
                  cognitive_level.questions,
                  false,
                  cognitive_level.id
                )
              }}
            </template>
            <template v-else>-</template>
          </b-td>
          <!-- DESARROLLO BREVE -->
          <b-td class="text-center">
            <div
              v-if="
                cognitive_level.questions.filter(
                  (x) =>
                    x.question_type == 1 && x.max_answer_length_type != null
                ).length > 0
              "
            >
              <div
                class="underline-cell"
                :id="`cl-questions-cell-${cognitive_level.id}-${test.id}-q_type-1-q_sub_type-1`"
              >
                {{
                  cognitive_level.questions.filter(
                    (x) =>
                      x.question_type == 1 && x.max_answer_length_type != null
                  ).length
                }}
              </div>
              <template
                v-for="question in cognitive_level.questions.filter(
                  (x) =>
                    x.question_type == 1 && x.max_answer_length_type != null
                )"
              >
                <b-modal
                  :key="`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`"
                  :id="`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`"
                  :title="`Editar ${$getVisibleNames(
                    'evaluations2.redactionquestion',
                    false,
                    'Pregunta'
                  )}`"
                  hide-footer
                  size="lg"
                >
                  <NewQuestionForm
                    :test_id="question.test"
                    :evaluation_criteria_micro_ids="
                      evaluation_criteria_micro_ids
                    "
                    :Question="question"
                    :evaluation_situations="evaluation_situations"
                    :show_delete_button="true"
                    @closeModal="
                      $bvModal.hide(
                        `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                      )
                    "
                  ></NewQuestionForm> </b-modal
              ></template>
              <b-popover
                :target="`cl-questions-cell-${cognitive_level.id}-${test.id}-q_type-1-q_sub_type-1`"
                triggers="hover"
              >
                <template #title>Preguntas</template>
                <div
                  v-for="question in cognitive_level.questions.filter(
                    (x) =>
                      x.question_type == 1 && x.max_answer_length_type != null
                  )"
                  :key="
                    question.id +
                    '-' +
                    question.question_type +
                    '-' +
                    cognitive_level.id +
                    '-' +
                    test.id
                  "
                >
                  <b-icon icon="circle-fill" scale="0.35"></b-icon>
                  Pregunta N°
                  <span v-if="question.segment_order != null">
                    {{ question.segment_order }}.
                  </span>
                  {{ question.order }}
                  <button-edit
                    v-if="allows_crud && !question.is_locked"
                    class="m-0 p-0"
                    v-b-tooltip.top.noninteractive.v-secondary="
                      `Editar Pregunta`
                    "
                    @click="
                      $bvModal.show(
                        `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                      )
                    "
                  ></button-edit>
                </div>
              </b-popover>
            </div>
            <template v-else>-</template>
          </b-td>
          <!-- PUNTAJE -->
          <b-td class="text-center">
            <template
              v-if="
                cognitive_level.questions.filter(
                  (x) =>
                    x.question_type == 1 && x.max_answer_length_type != null
                ).length > 0
              "
            >
              {{
                cognitive_level.questions
                  .filter(
                    (x) =>
                      x.question_type == 1 && x.max_answer_length_type != null
                  )
                  .reduce((acc, x) => acc + parseFloat(x.max_score), 0)
              }}
            </template>
            <template v-else>-</template>
          </b-td>
          <!-- DESARROLLO EXTENSO -->
          <b-td class="text-center">
            <div
              v-if="
                cognitive_level.questions.filter(
                  (x) =>
                    x.question_type == 1 && x.max_answer_length_type == null
                ).length > 0
              "
            >
              <div
                :id="`cl-questions-cell-${cognitive_level.id}-${test.id}-q_type-1-q_sub_type-2`"
                class="underline-cell"
              >
                {{
                  cognitive_level.questions.filter(
                    (x) =>
                      x.question_type == 1 && x.max_answer_length_type == null
                  ).length
                }}
              </div>
              <template
                v-for="question in cognitive_level.questions.filter(
                  (x) =>
                    x.question_type == 1 && x.max_answer_length_type == null
                )"
              >
                <b-modal
                  :key="`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`"
                  :id="`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`"
                  :title="`Editar ${$getVisibleNames(
                    'evaluations2.redactionquestion',
                    false,
                    'Pregunta'
                  )}`"
                  hide-footer
                  size="lg"
                >
                  <NewQuestionForm
                    :test_id="question.test"
                    :evaluation_criteria_micro_ids="
                      evaluation_criteria_micro_ids
                    "
                    :Question="question"
                    :evaluation_situations="evaluation_situations"
                    :show_delete_button="true"
                    @closeModal="
                      $bvModal.hide(
                        `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                      )
                    "
                  ></NewQuestionForm>
                </b-modal>
              </template>
              <b-popover
                :target="`cl-questions-cell-${cognitive_level.id}-${test.id}-q_type-1-q_sub_type-2`"
                triggers="hover"
              >
                <template #title>Preguntas</template>
                <div
                  v-for="question in cognitive_level.questions.filter(
                    (x) =>
                      x.question_type == 1 && x.max_answer_length_type == null
                  )"
                  :key="
                    question.id +
                    '-' +
                    question.question_type +
                    '-' +
                    cognitive_level.id +
                    '-' +
                    test.id
                  "
                >
                  <b-icon icon="circle-fill" scale="0.35"></b-icon>
                  Pregunta N°
                  <span v-if="question.segment_order != null">
                    {{ question.segment_order }}.
                  </span>
                  {{ question.order }}
                  <button-edit
                    v-if="allows_crud && !question.is_locked"
                    class="m-0 p-0"
                    v-b-tooltip.top.noninteractive.v-secondary="
                      `Editar Pregunta`
                    "
                    @click="
                      $bvModal.show(
                        `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                      )
                    "
                  ></button-edit>
                </div>
              </b-popover>
            </div>
            <template v-else>-</template>
          </b-td>
          <!-- PUNTAJE -->
          <b-td class="text-center">
            <template
              v-if="
                cognitive_level.questions.filter(
                  (x) =>
                    x.question_type == 1 && x.max_answer_length_type == null
                ).length > 0
              "
            >
              {{
                cognitive_level.questions
                  .filter(
                    (x) =>
                      x.question_type == 1 && x.max_answer_length_type == null
                  )
                  .reduce((acc, x) => acc + parseFloat(x.max_score), 0)
              }}
            </template>
            <template v-else>-</template>
          </b-td>
          <!-- SELECCIÓN SIMPLE -->
          <b-td class="text-center">
            <div
              v-if="
                cognitive_level.questions.filter(
                  (x) => x.question_type == 2 && x.subtype == 1
                ).length > 0
              "
            >
              <div
                :id="`cl-questions-cell-${cognitive_level.id}-${test.id}-q_type-2-q_sub_type-1`"
                class="underline-cell"
              >
                {{
                  cognitive_level.questions.filter(
                    (x) => x.question_type == 2 && x.subtype == 1
                  ).length
                }}
              </div>
              <template
                v-for="question in cognitive_level.questions.filter(
                  (x) => x.question_type == 2 && x.subtype == 1
                )"
              >
                <b-modal
                  :key="`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`"
                  :id="`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`"
                  :title="`Editar ${$getVisibleNames(
                    'evaluations2.redactionquestion',
                    false,
                    'Pregunta'
                  )}`"
                  hide-footer
                  size="lg"
                >
                  <NewQuestionForm
                    :test_id="question.test"
                    :evaluation_criteria_micro_ids="
                      evaluation_criteria_micro_ids
                    "
                    :Question="question"
                    :evaluation_situations="evaluation_situations"
                    :show_delete_button="true"
                    @closeModal="
                      $bvModal.hide(
                        `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                      )
                    "
                  ></NewQuestionForm> </b-modal
              ></template>
              <b-popover
                :target="`cl-questions-cell-${cognitive_level.id}-${test.id}-q_type-2-q_sub_type-1`"
                triggers="hover"
              >
                <template #title>Preguntas</template>
                <div
                  v-for="question in cognitive_level.questions.filter(
                    (x) => x.question_type == 2 && x.subtype == 1
                  )"
                  :key="
                    question.id +
                    '-' +
                    question.question_type +
                    '-' +
                    cognitive_level.id +
                    '-' +
                    test.id
                  "
                >
                  <b-icon icon="circle-fill" scale="0.35"></b-icon>
                  Pregunta N°
                  <span v-if="question.segment_order != null">
                    {{ question.segment_order }}.
                  </span>
                  {{ question.order }}
                  <button-edit
                    v-if="allows_crud && !question.is_locked"
                    class="m-0 p-0"
                    v-b-tooltip.top.noninteractive.v-secondary="
                      `Editar Pregunta`
                    "
                    @click="
                      $bvModal.show(
                        `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                      )
                    "
                  ></button-edit>
                </div>
              </b-popover>
            </div>
            <template v-else>-</template>
          </b-td>
          <!-- PUNTAJE -->
          <b-td class="text-center">
            <template
              v-if="
                cognitive_level.questions.filter(
                  (x) => x.question_type == 2 && x.subtype == 1
                ).length > 0
              "
            >
              {{
                cognitive_level.questions
                  .filter((x) => x.question_type == 2 && x.subtype == 1)
                  .reduce((acc, x) => acc + parseFloat(x.max_score), 0)
              }}
            </template>
            <template v-else>-</template>
          </b-td>
          <!-- SELECCIÓN MÚLTIPLE -->
          <b-td class="text-center">
            <div
              v-if="
                cognitive_level.questions.filter(
                  (x) => x.question_type == 2 && x.subtype == 2
                ).length > 0
              "
            >
              <div
                :id="`cl-questions-cell-${cognitive_level.id}-${test.id}-q_type-2-q_sub_type-2`"
                class="underline-cell"
              >
                {{
                  cognitive_level.questions.filter(
                    (x) => x.question_type == 2 && x.subtype == 2
                  ).length
                }}
              </div>
              <template
                v-for="question in cognitive_level.questions.filter(
                  (x) => x.question_type == 2 && x.subtype == 2
                )"
              >
                <b-modal
                  :key="`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`"
                  :id="`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`"
                  :title="`Editar ${$getVisibleNames(
                    'evaluations2.redactionquestion',
                    false,
                    'Pregunta'
                  )}`"
                  hide-footer
                  size="lg"
                >
                  <NewQuestionForm
                    :test_id="question.test"
                    :evaluation_criteria_micro_ids="
                      evaluation_criteria_micro_ids
                    "
                    :Question="question"
                    :evaluation_situations="evaluation_situations"
                    :show_delete_button="true"
                    @closeModal="
                      $bvModal.hide(
                        `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                      )
                    "
                  ></NewQuestionForm>
                </b-modal>
              </template>
              <b-popover
                :target="`cl-questions-cell-${cognitive_level.id}-${test.id}-q_type-2-q_sub_type-2`"
                triggers="hover"
              >
                <template #title>Preguntas</template>
                <div
                  v-for="question in cognitive_level.questions.filter(
                    (x) => x.question_type == 2 && x.subtype == 2
                  )"
                  :key="
                    question.id +
                    '-' +
                    question.question_type +
                    '-' +
                    cognitive_level.id +
                    '-' +
                    test.id
                  "
                >
                  <b-icon icon="circle-fill" scale="0.35"></b-icon>
                  Pregunta N°
                  <span v-if="question.segment_order != null">
                    {{ question.segment_order }}.
                  </span>
                  {{ question.order }}
                  <button-edit
                    v-if="allows_crud && !question.is_locked"
                    class="m-0 p-0"
                    v-b-tooltip.top.noninteractive.v-secondary="
                      `Editar Pregunta`
                    "
                    @click="
                      $bvModal.show(
                        `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                      )
                    "
                  ></button-edit>
                </div>
              </b-popover>
            </div>
            <template v-else>-</template>
          </b-td>
          <!-- PUNTAJE -->
          <b-td class="text-center">
            <template
              v-if="
                cognitive_level.questions.filter(
                  (x) => x.question_type == 2 && x.subtype == 2
                ).length > 0
              "
            >
              {{
                cognitive_level.questions
                  .filter((x) => x.question_type == 2 && x.subtype == 2)
                  .reduce((acc, x) => acc + parseFloat(x.max_score), 0)
              }}
            </template>
            <template v-else>-</template>
          </b-td>
          <!-- EMPAREJAMIENTO -->
          <b-td class="text-center">
            <div
              v-if="
                cognitive_level.questions.filter((x) => x.question_type == 4)
                  .length > 0
              "
            >
              <div
                :id="`cl-questions-cell-${cognitive_level.id}-${test.id}-q_type-4-q_sub_type-1`"
                class="underline-cell"
              >
                {{
                  cognitive_level.questions.filter((x) => x.question_type == 4)
                    .length
                }}
              </div>
              <template
                v-for="question in cognitive_level.questions.filter(
                  (x) => x.question_type == 4
                )"
              >
                <b-modal
                  :key="`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`"
                  :id="`modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`"
                  :title="`Editar ${$getVisibleNames(
                    'evaluations2.redactionquestion',
                    false,
                    'Pregunta'
                  )}`"
                  hide-footer
                  size="lg"
                >
                  <NewQuestionForm
                    :test_id="question.test"
                    :evaluation_criteria_micro_ids="
                      evaluation_criteria_micro_ids
                    "
                    :Question="question"
                    :evaluation_situations="evaluation_situations"
                    :show_delete_button="true"
                    @closeModal="
                      $bvModal.hide(
                        `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                      )
                    "
                  ></NewQuestionForm>
                </b-modal>
              </template>
              <b-popover
                :target="`cl-questions-cell-${cognitive_level.id}-${test.id}-q_type-4-q_sub_type-1`"
                triggers="hover"
              >
                <template #title>Preguntas</template>
                <div
                  v-for="question in cognitive_level.questions.filter(
                    (x) => x.question_type == 4
                  )"
                  :key="
                    question.id +
                    '-' +
                    question.question_type +
                    '-' +
                    cognitive_level.id +
                    '-' +
                    test.id
                  "
                >
                  <b-icon icon="circle-fill" scale="0.35"></b-icon>
                  Pregunta N°
                  <span v-if="question.segment_order != null">
                    {{ question.segment_order }}.
                  </span>
                  {{ question.order }}
                  <button-edit
                    v-if="allows_crud && !question.is_locked"
                    class="m-0 p-0"
                    v-b-tooltip.top.noninteractive.v-secondary="
                      `Editar Pregunta`
                    "
                    @click="
                      $bvModal.show(
                        `modal-updated-question-nw-st-3-${question.test}-${question.question_type}-${question.id}-${cognitive_level.id}`
                      )
                    "
                  ></button-edit>
                </div>
              </b-popover>
            </div>
            <template v-else>-</template>
          </b-td>
          <!-- PUNTAJE -->
          <b-td class="text-center">
            <template
              v-if="
                cognitive_level.questions.filter((x) => x.question_type == 4)
                  .length > 0
              "
            >
              {{
                cognitive_level.questions
                  .filter((x) => x.question_type == 4)
                  .reduce((acc, x) => acc + parseFloat(x.max_score), 0)
              }}
            </template>
            <template v-else>-</template>
          </b-td>
        </b-tr>
      </b-tbody>
      <b-tfoot>
        <b-tr class="cl-tfoot">
          <b-td class="text-center">TOTAL</b-td>
          <b-td class="text-center">{{ totalCountCL }}</b-td>
          <!-- DESARROLLO BREVE -->
          <b-td class="text-center">
            <!-- {{
              totalCountQuestion(
                questionList.filter(
                  (x) =>
                    x.question_type == 1 && x.max_answer_length_type != null
                )
              )
            }} -->
          </b-td>
          <b-td class="text-center">
            <!-- {{
              totalCountQuestionScoreCL(
                questionList.filter(
                  (x) =>
                    x.question_type == 1 && x.max_answer_length_type != null
                )
              )
            }} -->
          </b-td>
          <!-- DESARROLLO EXTENSO  -->
          <b-td class="text-center">
            <!-- {{
              totalCountQuestion(
                questionList.filter(
                  (x) =>
                    x.question_type == 1 && x.max_answer_length_type == null
                )
              )
            }} -->
          </b-td>
          <b-td class="text-center">
            <!-- {{
              totalCountQuestionScoreCL(
                questionList.filter(
                  (x) =>
                    x.question_type == 1 && x.max_answer_length_type == null
                )
              )
            }} -->
          </b-td>
          <!-- SELECCIÓN SIMPLE -->
          <b-td class="text-center">
            <!-- {{
            totalCountQuestion(
              questionList.filter((x) => x.question_type == 2 && x.subtype == 1)
            )
          }} -->
          </b-td>
          <b-td class="text-center">
            <!-- {{
              totalCountQuestionScoreCL(
                questionList.filter(
                  (x) => x.question_type == 2 && x.subtype == 1
                )
              )
            }} -->
          </b-td>
          <!-- SELECCIÓN MÚLTIPLE -->
          <b-td class="text-center">
            <!-- {{
            totalCountQuestion(
              questionList.filter((x) => x.question_type == 2 && x.subtype == 2)
            )
          }} -->
          </b-td>
          <b-td class="text-center">
            <!-- {{
              totalCountQuestionScoreCL(
                questionList.filter(
                  (x) => x.question_type == 2 && x.subtype == 2
                )
              )
            }} -->
          </b-td>
          <!-- EMPAREJAMIENTO -->
          <b-td class="text-center">
            <!-- {{
            totalCountQuestion(questionList.filter((x) => x.question_type == 4))
          }} -->
          </b-td>
          <b-td class="text-center">
            <!-- {{
              totalCountQuestionScoreCL(
                questionList.filter((x) => x.question_type == 4)
              )
            }} -->
          </b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
    <b-modal
      v-if="test"
      :id="`modal-created-question-nw-st-cgl-${test.id}`"
      :title="`Crear Pregunta`"
      hide-footer
      size="lg"
    >
      <NewQuestionForm
        :test_id="test.id"
        :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
        :order="
          filterQuestionBySegment().length > 0
            ? filterQuestionBySegment()[filterQuestionBySegment().length - 1]
                .order + 1
            : 1
        "
        :evaluation_situations="evaluation_situations"
        @closeModal="
          $bvModal.hide(`modal-created-question-nw-st-cgl-${test.id}`)
        "
      ></NewQuestionForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "NewCognitiveLevelST",
  components: {
    NewQuestionForm: () =>
      import(
        "@/components/new-evaluations/Evaluation/Questions/NewQuestionForm"
      ),
  },
  props: {
    allows_crud: {
      type: Boolean,
      default: false,
    },
    test: {
      type: Object,
      required: true,
    },
    evaluation_criteria_micro_ids: {
      type: Array,
      required: true,
    },
    evaluation_situations: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      question_types: [
        { id: 1, value: "Desarrollo" },
        { id: 2, value: "Selección" },
        // { id: 3, value: "Auto Completar" },
        { id: 4, value: "Emparejamiento" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      taxonomy: names.TAXONOMY,
      rubrics: names.NEW_RUBRIC_INSTRUMENTS,
      rubricCriterias: names.NEW_RUBRIC_CRITERIAS,
      test_segments: names.NEW_TEST_SEGMENTS,
      evaluation_criteria_micros: names.EVALUATION_CRITERIAS,
      redaction_questions: names.NEW_REDACTION_QUESTIONS,
      multiple_choice_questions: names.NEW_MULTIPLE_CHOICE_QUESTIONS,
      // filling_questions: names.NEW_FILLING_QUESTIONS,
      matching_questions: names.NEW_MATCHING_QUESTIONS,
    }),
    evaluationSituation() {
      if (!this.test) return null;
      return this.evaluation_situations.find(
        (x) => x.id == this.test.evaluation_situation
      );
    },
    rubric() {
      return this.rubrics.find((x) => x.test == this.test.id);
    },
    rubricCriteriaList() {
      if (!this.rubric) return [];
      return this.rubricCriterias
        .filter((x) => x.rubric == this.rubric.id)
        .map((x) => {
          const cognitive_levels = [
            ...new Set(
              this.evaluation_criteria_micros
                .filter(
                  (ecm) =>
                    x.evaluation_criteria_micro == ecm.id ||
                    x.evaluation_criterias_micro.includes(ecm.id)
                )
                .map((x) => this.getCognitiveLevelId(x.verb))
            ),
          ];
          return {
            ...x,
            cognitive_levels: cognitive_levels,
          };
        })
        .sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
    },
    redactionQuestions() {
      return this.redaction_questions.filter((x) => x.test == this.test.id);
    },
    multipleChoiceQuestions() {
      return this.multiple_choice_questions.filter(
        (x) => x.test == this.test.id
      );
    },
    // fillingQuestions() {
    //   return this.filling_questions.filter((x) => x.test == this.test.id);
    // },
    matchingQuestions() {
      return this.matching_questions.filter((x) => x.test == this.test.id);
    },
    questionList() {
      let list = [];
      list = list.concat(
        this.redactionQuestions.map((question) => ({
          ...question,
          question_type: 1,
          segment_order:
            (this.test_segments.find((s) => s.id == question.segment) || {})
              .order || null,
        }))
      );
      list = list.concat(
        this.multipleChoiceQuestions.map((question) => ({
          ...question,
          question_type: 2,
          segment_order:
            (this.test_segments.find((s) => s.id == question.segment) || {})
              .order || null,
        }))
      );
      // list = list.concat(
      //   this.fillingQuestions.map((question) => ({
      //     ...question,
      //     question_type: 3,
      //     segment_order:
      //       (this.test_segments.find((s) => s.id == question.segment) || {})
      //         .order || null,
      //   }))
      // );
      list = list.concat(
        this.matchingQuestions.map((question) => ({
          ...question,
          question_type: 4,
          segment_order:
            (this.test_segments.find((s) => s.id == question.segment) || {})
              .order || null,
        }))
      );
      if (this.institution && this.institution.internal_use_id == "duoc_uc") {
        list = list.map((question) => {
          const rubric_critera = this.rubricCriteriaList.find(
            (s) => s.id == question.linked_rubric_criteria
          );
          return {
            ...question,
            total_cognitive_levels: rubric_critera
              ? rubric_critera.cognitive_levels
              : [],
            cognitive_levels: rubric_critera
              ? rubric_critera.cognitive_levels
              : [],
            evaluation_criteria_micro: rubric_critera
              ? rubric_critera.evaluation_criteria_micro
              : null,
            evaluation_criterias_micro: rubric_critera
              ? [rubric_critera.evaluation_criteria_micro]
              : [],
          };
        });
      } else {
        list = list.map((question) => {
          const cognitive_levels = this.evaluation_criteria_micros
            .filter(
              (x) =>
                question.evaluation_criteria_micro == x.id ||
                question.evaluation_criterias_micro.includes(x.id)
            )
            .map((x) => this.getCognitiveLevelId(x.verb));
          return {
            ...question,
            total_cognitive_levels: cognitive_levels,
            cognitive_levels: [...new Set(cognitive_levels)],
          };
        });
      }
      return list.sort(function (a, b) {
        if (a.segment_order < b.segment_order) return 1;
        if (a.segment_order > b.segment_order) return -1;
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });
    },
    cognitiveLevelList() {
      let cognitive_levels = [{ id: null, level: "N/A", name: "N/A" }];
      cognitive_levels = cognitive_levels.concat(
        this.taxonomy.cognitive_levels
      );
      return cognitive_levels.map((x) => {
        return {
          ...x,
          questions: this.questionList.filter((q) =>
            q.cognitive_levels.includes(x.id)
          ),
        };
      });
    },
    totalCountCL() {
      return this.countUniqueIL(this.questionList, true);
    },
  },
  methods: {
    filterQuestionBySegment(segment_id = null) {
      return this.questionList.filter((x) => x.segment == segment_id);
    },
    getCognitiveLevel(verb_id) {
      if (verb_id == null) return verb_id;
      return this.taxonomy.cognitive_levels.find((cognitive_level) =>
        cognitive_level.verbs.some((verb) => verb.id == verb_id)
      );
    },
    getCognitiveLevelId(verb_id) {
      if (verb_id == null) return verb_id;
      const cognitive_level = this.getCognitiveLevel(verb_id);
      return cognitive_level ? cognitive_level.id : null;
    },
    countUniqueIL(questions, total_count = false, cognitive_level_id = null) {
      if (total_count)
        return [
          ...new Set(
            questions
              .map((x) => {
                let list = [];
                if (x.evaluation_criterias_micro.length > 0)
                  list = x.evaluation_criterias_micro;
                if (x.evaluation_criteria_micro != null)
                  list = list.concat([x.evaluation_criteria_micro]);
                return list;
              })
              .flat()
          ),
        ].length;
      else {
        return questions.reduce((acc, obj) => {
          if (obj.total_cognitive_levels)
            return (
              obj.total_cognitive_levels.filter((x) => x == cognitive_level_id)
                .length + acc
            );
        }, 0);
      }
    },
    // totalCountQuestionScoreCL(questions_filter) {
    //   if (questions_filter.length == 0) return "-";
    //   return questions_filter.reduce(
    //     (acc, x) => acc + parseFloat(x.max_score),
    //     0
    //   );
    // },
    // totalCountQuestion(questions) {
    //   if (questions.length == 0) return "-";
    //   else return questions.length;
    // },
  },
  created() {},
};
</script>

<style scoped>
.th-class {
  background-color: var(--kl-menu-color) !important;
  color: var(--secondary-font-color);
  font-size: var(--secondary-font-size);
  text-align: center;
}
.underline-cell {
  text-decoration: underline;
  cursor: help;
}
.cl-tfoot {
  background-color: #eee;
}
</style>

